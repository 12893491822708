import { DateTime } from 'luxon'

import { GTM } from '@common/config'

import loadSnippet from './loadSnippet'

class DrmaxGtm {
  w: any // window
  layerName: string
  enabled: boolean

  constructor(w, d, s, layerName, gtmId) {
    this.w = w
    this.layerName = layerName
    this.enabled = !!gtmId
    this.w[this.layerName] = this.w[this.layerName] || []
    this.gtmPushEvent({ singlePageApp: true })

    if (this.enabled) {
      loadSnippet(w, d, s, layerName, gtmId)
    }
  }

  gtmPushEvent(gtmEvent) {
    if (GTM.log) {
      console[GTM.log || 'debug']('GTM event', gtmEvent)
    }

    if (this.enabled) {
      try {
        this.w[this.layerName].push(gtmEvent)
      } catch (err) {
        throw new Error(`GTM event push failed: ${err}`)
      }
    }
  }

  update() {
    this.gtmPushEvent({
      event: 'virtualPageview',
    })
  }

  pushOrderStatusEvent(orderStatus, shipping) {
    const { hasSuccessResponse, data } = orderStatus

    const pushEvent: any = {
      event: 'orderCheck',
      validity: hasSuccessResponse,
    }

    if (hasSuccessResponse) {
      const dateOfOrder = this.getDateOfOrder(data)
      pushEvent.order = {
        orderNumber: data.incrementId,
        orderStatus: data.statusHistory[0]?.message ?? data.parcelHistory[0]?.message,
        paymentStatus: data.payment?.isPaid,
        deliveryType: shipping?.method.translated,
        dateOfOrder,
        deliveryAddress: shipping?.addressText,
      }
    }
    this.gtmPushEvent(pushEvent)
  }

  getDateOfOrder({ statusHistory }) {
    if (statusHistory.length === 0) return undefined

    const canceledStatus = statusHistory.find((obj) => obj.status === 'canceled')
    const created = canceledStatus?.createdAt ?? statusHistory[0].createdAt

    const dateTime = DateTime.fromISO(created)
    if (!dateTime.isValid) return undefined

    return dateTime.toFormat('yyyy-MM-dd')
  }
}

export const gtm = new DrmaxGtm(window, document, 'script', 'dataLayerDRMAX', GTM.id)
