// import api, { DiagConsoleLogger, DiagLogLevel } from '@opentelemetry/api'
import { getWebAutoInstrumentations } from '@opentelemetry/auto-instrumentations-web'
import { ZoneContextManager } from '@opentelemetry/context-zone'
import {
  CompositePropagator,
  W3CBaggagePropagator,
  W3CTraceContextPropagator,
} from '@opentelemetry/core'
import { OTLPTraceExporter } from '@opentelemetry/exporter-trace-otlp-http'
import { registerInstrumentations } from '@opentelemetry/instrumentation'
import { Resource } from '@opentelemetry/resources'
import { BatchSpanProcessor } from '@opentelemetry/sdk-trace-base'
import { WebTracerProvider } from '@opentelemetry/sdk-trace-web'
import { ATTR_SERVICE_NAME } from '@opentelemetry/semantic-conventions'

import { API_BASE_URL, CURRENT_ENV, CURRENT_MUTATION } from '@common/config'

// if (IS_LOCAL_ENV) {
//   api.diag.setLogger(new DiagConsoleLogger(), DiagLogLevel.DEBUG)
// }

// The SemanticResourceAttributes is an enum that provides a set of predefined attribute keys for commonly used attributes in OpenTelemetry to maintain consistency across different OpenTelemetry implementations
const resource = new Resource({
  [ATTR_SERVICE_NAME]: `order-tracking-client-${CURRENT_MUTATION}-${CURRENT_ENV}`,
})

// this endpoint contains a path since this exporter is signal specific (traces)
// send it to Eshop Admin server to avoid exposing NewRelic API key, see: https://docs.newrelic.com/docs/more-integrations/open-source-telemetry-integrations/opentelemetry/best-practices/opentelemetry-best-practices-browser
const exporter = new OTLPTraceExporter({
  url: `${API_BASE_URL}/otel/traces`,
  headers: {}, // needs to be here to send data using xhr instead of sendBeacon, see: https://github.com/open-telemetry/opentelemetry-js/issues/3062#issuecomment-1189189494
})

const provider = new WebTracerProvider({
  resource,
  spanProcessors: [
    // Uncomment this to enable debugging using consoleExporter
    // new SimpleSpanProcessor(new ConsoleSpanExporter()),
    // The BatchSpanProcessor is responsible for batching and exporting spans to the configured exporter (newRelicExporter in this case).
    new BatchSpanProcessor(exporter),
  ],
})

// ZoneContextManager is a context manager implementation based on the Zone.js library. It enables context propagation within the application using zones.
provider.register({
  contextManager: new ZoneContextManager(),
  // Configure the propagator to enable context propagation between services using the W3C Trace Headers
  propagator: new CompositePropagator({
    propagators: [new W3CBaggagePropagator(), new W3CTraceContextPropagator()],
  }),
})

export const init = () => {
  registerInstrumentations({
    instrumentations: [
      getWebAutoInstrumentations({
        '@opentelemetry/instrumentation-xml-http-request': {
          propagateTraceHeaderCorsUrls: [/.+/g],
          ignoreUrls: [/googleapis/, /sentry/],
          clearTimingResources: true,
        },
      }),
    ],
  })
}
