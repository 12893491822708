<template>
  <header class="header" id="header" itemscope itemtype="https://schema.org/WPHeader">
    <div class="header__middle">
      <meta itemprop="name" content="Dr.Max" />
      <meta itemprop="url" :content="eshopUrl" />
      <a
        :href="eshopUrl"
        class="header__logo default mx-2 d-none d-lg-block"
        itemprop="image logo"
        itemscope
        itemtype="https://schema.org/ImageObject"
      >
        <img
          v-if="isApteka"
          itemprop="url"
          src="@/assets/images/apteka-logo.svg"
          alt="Dr.Max"
          width="274"
          height="50"
        />
        <img
          v-else-if="isDrogeria"
          itemprop="url"
          src="@/assets/images/drogeria-logo.svg"
          alt="Dr.Max"
          width="334"
          height="50"
        />
        <img
          v-else
          itemprop="url"
          src="@/assets/images/logo.svg"
          alt="Dr.Max"
          width="147"
          height="50"
        />
      </a>

      <a
        :href="eshopUrl"
        class="header__logo mx-2 d-lg-none"
        itemprop="image logo"
        itemscope
        itemtype="https://schema.org/ImageObject"
      >
        <img
          v-if="isApteka"
          itemprop="url"
          src="@/assets/images/apteka-logo-mobile.svg"
          alt="Dr.Max"
          width="100"
          height="43"
        />
        <img
          v-else-if="isDrogeria"
          itemprop="url"
          src="@/assets/images/drogeria-logo-mobile.svg"
          alt="Dr.Max"
          width="100"
          height="43"
        />
        <img
          v-else
          itemprop="url"
          src="@/assets/images/logo.svg"
          alt="Dr.Max"
          width="100"
          height="43"
        />
      </a>

      <p class="h2 font-weight-light m-0 d-none d-sm-block header__slogan">
        {{ $t('title') }}
      </p>

      <a
        v-if="robotEnabled"
        href="#robot"
        class="col d-block d-lg-none text-end me-2"
        title="Robot Tom"
      >
        <img src="@/assets/images/robot-icon.svg" alt="Robot Tom" width="50" height="50" />
      </a>
    </div>
  </header>
</template>

<script setup lang="ts">
import { computed } from 'vue'

import { CURRENT_MUTATION } from '@common/config'

import { useUrls } from '../../composables/url'

const { eshopUrl } = useUrls()

defineProps<{
  robotEnabled?: boolean
}>()

const isApteka = computed(() => CURRENT_MUTATION === 'pl-apteka')
const isDrogeria = computed(() => CURRENT_MUTATION === 'pl-drogeria')
</script>

<style lang="scss" scoped>
@media (min-width: 992px) {
  .header__slogan {
    color: #58595a;
  }
}
</style>
