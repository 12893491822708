<template>
  <main class="page__content">
    <div class="container">
      <div class="text-center">
        <img
          class="img-fluid"
          src="@/assets/images/404-page.png"
          :title="$t('notFound.title')"
          :alt="$t('notFound.title')"
          height="331"
        />

        <h1>{{ $t('notFound.title') }} <span class="text-muted">(404)</span></h1>
      </div>

      <ul class="mx-auto mt-4">
        <li class="mb-2">{{ $t('notFound.description') }}</li>
        <li class="mb-2"><span v-html="$t('notFound.recommendation', { eshopUrl })"></span></li>
      </ul>

      <div class="mx-auto">
        <a :href="eshopUrl" class="btn btn-highlighted btn-md my-2">
          {{ $t('notFound.button') }}
        </a>
      </div>
    </div>
  </main>
</template>

<script setup lang="ts">
import { useUrls } from '../composables/url'

const { eshopUrl } = useUrls()
</script>
